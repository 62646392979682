import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class LegalNotice extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="DPM Solutions - Mentions légales" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--50 pb--100 bg_image bg_image--5"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Mentions légales</h2>
                  {/* <p>
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text.{" "}
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Portfolio Details */}
        <div className="rn-portfolio-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-details">
                  <div className="inner">
                    {/* <h2>Mentions légales</h2> */}
                    <p className="subtitle">DPM Solutions</p>
                    <p style={{ margin: 0 }}>
                      Nom de l'entreprise : DPM Solutions
                    </p>
                    <p style={{ margin: 0 }}>Forme juridique : EURL</p>
                    <p style={{ margin: 0 }}>
                      Adresse : 1 Rue des Ardennes, Bâtiment D, 57410
                      ROHRBACH-LES-BITCHE
                    </p>
                    <p style={{ margin: 0 }}>Téléphone : +33 6 51 75 68 72</p>
                    <p style={{ margin: 0 }}>
                      Email : contact@dpm-solutions.fr
                    </p>
                    <p style={{ margin: 0 }}>SIRET : 97772855900013</p>
                    <p style={{ margin: 0 }}>N° TVA : FR70977728559</p>
                    <p style={{ margin: 0 }}>Capital social : 1000€</p>
                    <p style={{ margin: 0 }}>
                      Directeur de la publication : Paul DAMPFHOEFFER (CEO)
                    </p>
                    <p style={{ margin: 0 }}>
                      Hébergeur du site : OVH - 2 rue Kellermann, 59100 Roubaix,
                      France
                    </p>

                    <p className="subtitle">Propriété intellectuelle</p>
                    <p>
                      Le contenu de ce site web (textes, images, vidéos, etc.)
                      est la propriété de DPM Solutions ou de ses fournisseurs
                      de contenu et est protégé par les lois françaises et
                      internationales sur le droit d'auteur et la propriété
                      intellectuelle. Toute utilisation non autorisée du contenu
                      du site est strictement interdite
                    </p>

                    <p className="subtitle">Accès au site</p>
                    <p>
                      L'accès au site est ouvert en tout lieu, 7 jours sur 7, 24
                      heures sur 24, sauf dans les cas de force majeure,
                      d'interruptions prévues ou non, pouvant résulter de
                      besoins de maintenance.
                    </p>
                    <p>
                      En cas de changements, d'interruptions planifiées ou non,
                      ou de suspension des services, le site dpm-solutions.fr ne
                      peut être tenu pour responsable.
                    </p>

                    <p className="subtitle">
                      Collecte d'informations personnelles
                    </p>
                    <p>
                      Les informations à caractère personnel bénéficient des
                      dispositions protectrices énoncées dans la loi n° 78-87 du
                      6 janvier 1978, la loi n° 2004-801 du 6 août 2004,
                      l'article L. 226-13 du Code pénal ainsi que de la
                      Directive Européenne du 24 octobre 1995.
                    </p>
                    <p>
                      En toute circonstance, ce site internet recueille
                      exclusivement des données personnelles concernant
                      l'utilisateur (telles que nom, prénom, adresse e-mail...)
                      dans le seul but de fournir les services proposés par le
                      site, notamment à travers le formulaire de contact.
                    </p>
                    <p>
                      L'utilisateur transmet ces informations en toute
                      conscience, particulièrement lorsqu'il les saisit
                      lui-même. Dans ce contexte, le site informe clairement
                      l'utilisateur sur le caractère obligatoire ou facultatif
                      des informations qu'il peut être amené à fournir.
                    </p>

                    <p className="subtitle">Responsabilité</p>
                    <p>
                      Ce site propose des informations fournies par des entités
                      externes ainsi que des liens hypertextes dirigeant vers
                      d'autres sites dont le contenu n'a pas été élaboré par DPM
                      Solutions. Les contenus disponibles sur ce site le sont à
                      titre informatif. La présence d'un lien de ce site vers un
                      autre ne constitue pas une approbation du site en question
                      ni de son contenu. Il incombe aux visiteurs d'utiliser ces
                      informations en faisant preuve de discernement et de
                      critique.
                    </p>
                    <p>
                      DPM Solutions s'efforce de présenter des informations
                      exactes et à jour sur ce site, mais ne peut garantir leur
                      exhaustivité ni leur précision. DPM Solutions décline
                      toute responsabilité pour les préjudices directs ou
                      indirects résultant de l'utilisation de ce site.
                    </p>
                    <p>
                      Ces mentions légales sont régies par la loi française. En
                      cas de litige, les tribunaux français auront compétence
                      exclusive.
                    </p>
                    <p>Dernière mise à jour : 30/08/2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Details */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default LegalNotice;
