import React, { useState } from "react";
import emailjs from "emailjs-com";

const Result = () => {
  return (
    <p className="success-message">
      Votre message a bien été envoyé ! Vous serez recontactés sous 48h.
    </p>
  );
};
function ContactForm({ props }) {
  const [result, showresult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_5nv0knd",
        "template_jji9p7r",
        e.target,
        "tF2WKj_fsBlhZEp3h"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showresult(true);

    // Event snippet for Formulaire de contact conversion page
    const script = document.createElement("script");
    script.innerText =
      "gtag('event', 'conversion', {'send_to': 'AW-11318246035/-vZ8CJ7B8N8YEJP1-pQq'});";
    document.head.appendChild(script);

    setTimeout(() => {
      showresult(false);
    }, 7000);
  };

  return (
    <form action="" onSubmit={sendEmail}>
      <div className="rn-form-group">
        <input type="text" name="fullname" placeholder="Nom" required />
      </div>

      <div className="rn-form-group">
        <input type="email" name="email" placeholder="Email" required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="phone" placeholder="Téléphone" required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="subject" placeholder="Sujet" required />
      </div>

      <div className="rn-form-group">
        <textarea name="message" placeholder="Message" required></textarea>
      </div>

      <div className="rn-form-group" id="submit-buttom">
        <button
          className="rn-button-style--2 btn-solid"
          type="submit"
          value="submit"
          name="submit"
          id="mc-embedded-subscribe"
        >
          Envoyer
        </button>
      </div>

      <div className="rn-form-group">{result ? <Result /> : null}</div>
    </form>
  );
}
export default ContactForm;
