import React, { Component, Fragment } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

class CounterOne extends Component {
  state = {
    didViewCountUp: false,
  };
  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };
  render() {
    let Data = [
      {
        countNum: 50,
        countTitle:
          'Missions réalisées avec succès pour tout autant de clients satisfaits',
        sign: '+',
      },
      {
        countNum: 5,
        countTitle: 'Note sur Google',
        textAfter: '/5',
        decimals: 0,
      },
      {
        countNum: 15,
        countTitle: 'Applications mobile réalisées et déployées',
        sign: '+',
      },
    ];

    return (
      <Fragment>
        <div className="row">
          {Data.map((value, index) => (
            <div
              className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12"
              key={index}
            >
              <h5 className="counter">
                <VisibilitySensor
                  onChange={this.onVisibilityChange}
                  offset={{ top: 10 }}
                  delayedCall
                >
                  <CountUp
                    end={this.state.didViewCountUp ? value.countNum : 0}
                    decimals={value.decimals ?? 0}
                  />
                </VisibilitySensor>
                {value.sign && (
                  <span
                    style={{
                      right: value.right ?? -35,
                      position: 'absolute',
                      top: value.top ?? -17,
                      fontSize: 40,
                    }}
                  >
                    {value.sign}
                  </span>
                )}
                {value.textAfter}
              </h5>
              <p className="description">{value.countTitle}</p>
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
}
export default CounterOne;
