import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <Tabs>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Prestataire très motivé, disponible et flexible. Ca a été
                      un vrai plaisir de travailler avec lui de bout en bout, et
                      son rendu était à la hauteur du reste.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Julien </span> - E3D Environnement
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Paul est un excellent développeur, la mission s'est
                      déroulée parfaitement de A à Z. Efficace, curieux et à
                      l'écoute, réactif, communiquant, Paul vise l'excellence
                      dans ce qu'il va délivrer.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Antoine </span> - Comdata
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Respecte les délais, est à l'écoute et fourni un travail
                      de qualité. Je le recommande si vous avez des projets en
                      React Native !
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Mehdi </span> - Tagether
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Paul n'a de crédo que le travail bien fait. Il est pointu,
                      véritablement compétent et sait solutionner rapidement des
                      problématiques complexes. C'est quelqu'un de confiance qui
                      est passionné par ce qu'il entreprend. Je le recommande
                      très vivement et j'apprécierais de travailler de nouveau
                      avec lui !
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>William </span> - Arpejio
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Paul est parvenu à suivre nos exigences et à délivrer plus
                      que nos attentes. Il est clair que via ce biais nous
                      souhaitons le recommander et surtout le remercier. [...]
                      Un grand merci Paul, vous vous êtes montré durant toute la
                      durée de notre projet, patient, intelligent, étonnant et
                      surtout très persévérant.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Gregory </span> - ComAgenda
                    </h6>
                  </div>
                </div>
              </TabPanel>

              {/* <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Chunk of Lorem Ipsum used since the 1500s is reproduced
                      below for those interested. Sections Bonorum et Malorum
                      original.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Amar Orthi </span> - COO, AMERIMAR ENTERPRISES, INC.
                    </h6>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      The standard chunk of Lorem Ipsum used since the 1500s is
                      reproduced below for those interested. Sections Bonorum et
                      Malorum original.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>Fatima Ma </span> - COO, AMERIMAR ENTERPRISES, INC.
                    </h6>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="rn-testimonial-content text-center">
                  <div className="inner">
                    <p>
                      Lorem Ipsum used since the 1500s is reproduced below for
                      those interested. Sections Bonorum et Malorum original.
                    </p>
                  </div>
                  <div className="author-info">
                    <h6>
                      <span>JON CUMMINS </span> - COO, AMERIMAR ENTERPRISES,
                      INC.
                    </h6>
                  </div>
                </div>
              </TabPanel> */}

              <TabList className="testimonial-thumb-wrapper">
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-1.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-2.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-3.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-6.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-9.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                {/* <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-5.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-6.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-7.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div className="testimonial-thumbnai">
                    <div className="thumb">
                      <img
                        src="/assets/images/client/testimonial-8.jpg"
                        alt="Testimonial Images"
                      />
                    </div>
                  </div>
                </Tab> */}
              </TabList>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Testimonial;
