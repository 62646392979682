import React, { Component } from "react";

const ServiceList = [
  {
    icon: "02",
    title: "Applications mobile",
    description: "Développement de vos applications Android et IOS",
  },
  {
    icon: "01",
    title: "Applications web",
    description:
      "Sites internet, plateformes sur-mesure, éco-systèmes digitaux, CMS, ...",
  },
  {
    icon: "03",
    title: "Maintenance applicative",
    description:
      "Maintenance préventive, corrective et évolutive de votre support digital",
  },
];

class ServiceOne extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          {ServiceList.map((val, i) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
              <div className="service service__style--1">
                <div className="icon">
                  <img
                    src={`/assets/images/icons/icon-${val.icon}.png`}
                    alt="DPM Solutions"
                  />
                </div>
                <div className="content">
                  <h4 className="title">{val.title}</h4>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceOne;
