import React, { Component } from 'react';

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <img src="/assets/images/brand/comdata.png" alt="Logo Images" />
          </li>
          <li>
            <img src="/assets/images/brand/tagether.png" alt="Logo Images" />
          </li>
          <li>
            <img src="/assets/images/brand/galadrim.png" alt="Logo Images" />
          </li>
          <li>
            <img src="/assets/images/brand/boltistruct.png" alt="Logo Images" />
          </li>
          <li>
            <img src="/assets/images/brand/comagenda.png" alt="Logo Images" />
          </li>
          <li>
            <img src="/assets/images/brand/e3d.png" alt="Logo Images" />
          </li>
          <li>
            <img src="/assets/images/brand/histoneo.jpeg" alt="Logo Images" />
          </li>
          {/* <li>
            <img src="/assets/images/brand/brand-03.png" alt="Logo Images" />
          </li> */}
          {/* <li>
            <img src="/assets/images/brand/brand-04.png" alt="Logo Images" />
          </li> */}
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
