import React, { Component } from "react";

class About extends Component {
  render() {
    let title = "Qui sommes-nous",
      description =
        "Jeune entreprise française spécialisée dans le développement informatique, nous proposons nos services à tout types d’entités et vous apportons les technologies et prestations nécessaires à votre évolution. Avec des solutions sur-mesure et en adéquations avec les évolutions des dernières technologies, nous vous proposons un juste équilibre entre technique et relation humaine de qualité.";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/about-1.jpg"
                    alt="About Images"
                  />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{title}</h2>
                    <p className="description">{description}</p>
                  </div>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Communication & Transparence</h3>
                        <p>
                          Nous plaçons la communication et la transparence au
                          centre de nos préoccupations.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">Performance & Sécurité</h3>
                        <p>
                          Nous visons l'excellence sur le plan de la performance
                          et la sécurité de nos applications.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default About;
